const formValidate = (form) => {
    let error = 0;
    let formReq = $(form).find('._req');

    formReq.each(function() {
        const input = $(this);
        formRemoveError(input);

        if (input.hasClass('_email')) {
            if (emailTest(input)) {
                input.addClass('_error');
                error++;
            }
        } else if (input.attr('name') === 'phone') {
            if (input.val().length < 10) {
                input.addClass('_error');
                error++;
            }
        } else if (input.attr('type') === 'checkbox' && !input.prop('checked')) {
            input.parent().addClass('_error');
            error++;
        } else {
            if (input.val() === '') {
                input.addClass('_error');
                error++;
            }
        }
    });
      
    return error;
}

function emailTest(input) {
    return !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/.test($(input).val());
}



function formRemoveError(input) {
    $(input).parent().removeClass('_error');
    $(input).removeClass('_error');
}

export {formValidate}
