//функція яка рахує ширину скролла
function calcScroll() {
    let $div = $('<div>').css({
        width: '50px',
        height: '50px',
        overflowY: 'scroll',
        visibility: 'hidden'  
    });

    $('body').append($div);

    let scrollWidth = $div[0].offsetWidth - $div[0].clientWidth;
    $div.remove();

    return scrollWidth;
}

export {calcScroll};