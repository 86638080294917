const postData = async (url, data) => {
    return $.ajax({
        url: url,
        method: 'POST',
        headers: {
            'Content-type': 'application/json'
        },
        data: data,
        dataType: 'text'
    });    
};


const getDate = async (url) => {

    let res = await fetch(url);

    if(!res.ok) {
        throw new Error(`Could not fetch ${url}, status: ${res.status}`);
    }

    return await res.json();
};

export {postData, getDate};