import { getDate } from "../services/requests";


const onlineBookingDate = () => {
    const selectItems = 'form .form__select__item';
    let date;
	let time;

    const formWorkshop = $('form#form-booking');    

    if (formWorkshop.length > 0) {
        //Робиться запит до бази з датами 
        getDate('date.json')
        .then(async res => {
            //console.log(res);

            // Ініціюється календар після отримання даних з бази
            initializeDatepicker(res);

            //Обчисляється вибрана по замовчуванню дата
            const selectedDate = getSelectedDate();    
            //console.log(selectedDate);
            localStorage.setItem('date', date);
            //console.log(date);
            //Шукається інформація по даній даті
            searchDateInDB(res, selectedDate); 
            chooseTime();
        })
        .catch(error => {
            console.log(error);
        });
    }









    //Ініціюється календар
    function initializeDatepicker (data) {
        $( "#datepicker" ).datepicker({
            dateFormat: "dd-mm-yy",
            firstDay: 1, //понеділок
            minDate: "+1d",
            maxDate: "+2m +1w",
            hideIfNoPrevNext: true,        
            onSelect: function(dateText, inst) {
                //console.log(dateText); 
                date = dateText;
                localStorage.setItem('date', date);
                //console.log(date);
                //Шукається інформація по даній даті 
                searchDateInDB(data, dateText);
            }
        });
    }    


    function getSelectedDate() {
        // Отримуємо вибрану дату
        const currentDate = $("#datepicker").datepicker("getDate");    
        // Форматуємо дату у потрібний формат
        const formattedDate = $.datepicker.formatDate("dd-mm-yy", currentDate);
        date = formattedDate;        
        return formattedDate;
    }


    function searchDateInDB (res, date) {
        // Перевірка, чи є обрана дата в базі
        if (res.hasOwnProperty(date)) {                
            // Отримуємо об'єкт, що відповідає обраній даті
            const selectedDateObject = res[date];
            // Отримуємо масив ключів об'єкта
            const keysArray = Object.keys(selectedDateObject);
            // Тепер ми маємо масив ключів, який можемо використовувати
            buildingAvailableDates(keysArray);
        } else {
            buildingAvailableDates();
        }
    }

    function buildingAvailableDates(keys) {  
        $(selectItems).hide(); 
        $('form .form__select__item.active').removeClass('active');
        time = "";
        localStorage.setItem('time', time);
        //console.log(time);     
        $(selectItems).fadeIn();
        if (keys) {
            // Перебираємо всі елементи з класом .form__select__item
            $(selectItems).each(function() {
                // Отримуємо значення атрибуту data-value
                var dataValue = $(this).attr('data-value');
                // Перевіряємо, чи присутнє поточне значення data-value у масиві keys
                if (keys.includes(dataValue)) {
                    // Якщо значення присутнє, ховаємо елемент
                    $(this).hide();
                }
            });
        }      
    }

    function chooseTime() {
        $(selectItems).on('click', function() {
            // Знімаємо попередній активний клас
            $('form .form__select__item.active').removeClass('active');
            // Додаємо активний клас до натиснутого блоку
            $(this).addClass('active');

            time = $(this).attr('data-value'); 
            localStorage.setItem('time', time);  
            //console.log(time);
        });
    }

       
    
}

export {onlineBookingDate};