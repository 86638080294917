const slider = () => {
    const sliderModal = $('.slider');
    const sliderWrapper = $('.slider__wrapper');

    $('.photosession__item').on('click', function() {
        const wrapper = $(this).parent().clone();
        const clickedIndex = $(this).index();
        wrapper.children().appendTo(sliderWrapper);        
        $(sliderWrapper).slick({
            dots: true,
            infinite: true,
            speed: 500,
            fade: true,
            cssEase: 'linear'            
        });
        $(sliderWrapper).slick('slickGoTo', clickedIndex);
        openSlider();
    });
    
    function openSlider() {
        $('body').css({
            'overflow': 'hidden',
            'margin-right': `${scroll}px`
        });
        $(sliderModal).css('display', 'flex');
        $(sliderModal).addClass('animate__animated animate__fadeIn');  
        $('.slider__close').on('click', () => {
            closeSlider();
        });
             
    }

    function closeSlider() {            
        $(sliderModal).addClass('animate__fadeOut');
        $('body').css({
            'overflow': '',
            'margin-right': '0'
        });
        setTimeout(() => {            
            $(sliderModal).css('display', 'none');
            $(sliderModal).removeClass('animate__fadeOut');
        }, 700);
        sliderWrapper.empty();
        sliderWrapper.removeClass('slick-initialized slick-slider slick-dotted');
    }
}

export {slider};