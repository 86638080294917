const cookies = () => {
    const cookiesStatus = localStorage.getItem('cookies');
    if (!cookiesStatus) {
        setTimeout(() => {
            $('.cookies').slideDown();
        }, 8000);
    }
    

    $('.cookies__btn').on('click', () => {
        $('.cookies').slideUp();
        localStorage.setItem('cookies', true);
    });
}

export {cookies};