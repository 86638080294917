const language = () => {
    const list = $('.language__item');
    
    
    function showList(language = 'en') {   
        //console.log('showList', language);     
        $(list).removeClass('active');
        list.filter(`[data-language="${language}"]`).addClass('active');
        $('html').attr('lang', `${language}`);
        namingFormInputs(language);
    }    
    
    function firstLanguageLoading() {
        let language = localStorage.getItem('language');
        //console.log('localStorage', language);
        if(language) {
            showList(language);
            showLanguageText(language);            
        } else {
            showList();
            showLanguageText();            
        }
        $('.language__indicator').css('transition', 'transform 0.5s');
    }

    firstLanguageLoading();    
    

    $.each(list, (i, item) => {
        $(item).on('click', function () {
            let language = $(this).data('language');
            //console.log('click', language);
            showList(language);            
            showLanguageText(language);
            localStorage.setItem('language', `${language}`);            
        });
    }); 
    
 
    function showLanguageText(language = 'en') {
        //console.log('showLanguageText', language);
        $('body [lang]').css('display', 'none');
        $(`body [lang='${language}']`).fadeIn();
    }


    function namingFormInputs(language = 'en') {
        const nameInput = $('input#formName');
        const phoneInput = $('input#formPhone');
        const subjectInput = $('input#formSubject');
        const messageInput = $('textarea#formMessage');

        const namePlaceholder = {
            'en': 'Name and surname',
            'fr': 'Nom et prénom',
            'ru': 'Имя и Фамилия'
        };
        const phonePlaceholder = {
            'en': 'Phone number',
            'fr': 'Numéro de téléphone',
            'ru': 'Номер телефона'
        };
        const subjectPlaceholder = {
            'en': 'Subject',
            'fr': 'Sujet',
            'ru': 'Тема'
        };
        const messagePlaceholder = {
            'en': 'Your message',
            'fr': 'Votre message',
            'ru': 'Ваше сообщение'
        };

        nameInput.attr('placeholder', namePlaceholder[language]);
        phoneInput.attr('placeholder', phonePlaceholder[language]);
        subjectInput.attr('placeholder', subjectPlaceholder[language]);
        messageInput.attr('placeholder', messagePlaceholder[language]);
    }

    
    
    
}

export {language};