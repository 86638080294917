import { smoothScroll } from "./modules/smoothScroll";
import { up } from "./modules/up";
import { sidemenu } from "./modules/menu";
import { video } from "./modules/video";
import { order } from "./modules/order";
import { language } from "./modules/language";
import { accordion } from "./modules/accordion";
import { checkNumInputs } from "./services/checkNumInputs";
import { cookies } from "./modules/cookies";
import { onlineBookingDate } from "./modules/onlineBookingDate";
import { slider } from "./modules/slider";

const isPolicy = $('body.page-policy').length > 0;
const isService = $('body.page-service').length > 0;

$(document).ready (function() {
	smoothScroll('a[href="#order"]');
	up('.up', 2000);
	sidemenu();	
	onlineBookingDate();
	order();
	checkNumInputs('input[name="phone"]');
	language();
	if (!isPolicy) {
		cookies();
	}
	if (isService) {
		slider();
	}
	new WOW().init();
	

		

});



