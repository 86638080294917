import { calcScroll } from "../services/calcScroll";

const hamburger = $('.hamburger'),
            menu = $('.sidemenu'),
            overlay = $('.overlay'),
            closeSelector = $('.sidemenu__close'),
            scroll = calcScroll();

const sidemenu = () => {
    

    fixedMenu('.hamburger-fixed');
    openMenu();


    $(closeSelector).on('click', () => {
        closeMenu();
    });

    $(overlay).on('click', (e) => {
        if($(e.target).is(overlay)) {
            closeMenu();
        }
    });

    $(document).on('keydown', (e) => {
        if(e.code === 'Escape' && $(menu).css('display') === 'block') {
            closeMenu();
        }
    });
    
    $('.sidemenu__list__item').each(function () {
        if($(this).children('a').length === 0) {
            $(this).on('click', (e) => {
                if($(e.target).is('span')) {
                    closeMenu();
                }
            });
        }
    });

}

function fixedMenu (selector) {
    $(window).scroll(function() {
        if($(this).scrollTop() > 1000) {
            $(selector).fadeIn();
        } else {
            $(selector).fadeOut();
        }
    });
}

function openMenu() {
    $(hamburger).on('click', function() {
        $(menu).addClass('animate__animated animate__fadeInRight');
        $(overlay).css('display', 'block');
        $(menu).css('display', 'block');
        $('body').css({
            'overflow': 'hidden',
            'margin-right': `${scroll}px`
        });
    });
}

function closeMenu() {  
    $('body').css({
        'overflow': '',
        'margin-right': '0'
    });
    $(menu).addClass('animate__fadeOutRight');
    setTimeout(function() {
        closeAccordion();
        $(overlay).css('display', 'none');
        $(menu).css('display', 'none');
        $(menu).removeClass('animate__fadeOutRight');
    }, 500);        
}

function closeAccordion() {
    $('.toggle').removeClass('toggle_active');
    $('.sidemenu__second').css('display', 'none');
}

export {sidemenu, closeMenu};