const checkNumInputs = (selector) => {
	const numInputs = $(selector);

	$(numInputs).on('input', function() {
		//видаляється все, що не є числом, - та +
		$(this).val($(this).val().replace(/[^\d+/-]/g, ''));
	});

};

export {checkNumInputs};