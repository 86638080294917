import { smoothScroll } from "./smoothScroll";

const up = (selector, offsetNumber) => {
    $(window).scroll(function() {
        if($(this).scrollTop() > offsetNumber) {
            $(selector).fadeIn();
        } else {
            $(selector).fadeOut();
        }
    });

    smoothScroll(selector, true);
}

export {up};