import { closeMenu } from "./menu";

    const menu = $('.sidemenu');

const smoothScroll = (triggerSelector, toUp = false) => {
    $(triggerSelector).on('click', function() {
        if (!toUp) {           
            let href = $(this).attr('href');            
            if ($(menu).css('display') === 'block') {
                closeMenu();
            }
            $('html, body').animate({
                scrollTop: $(href).offset().top
            });            
        } else {
            $('html, body').animate({
                scrollTop: 0
            });
        }
        
        return false;
    });
}

export {smoothScroll};
